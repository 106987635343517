<template>
  <div>
    <div class="not-nft">
      <h1>{{ t("非车主服饰NFT") }}</h1>

      <div class="nft-content">
        <div class="nft_child" v-for="(item, i) in translatedNftData" :key="i">
          <div class="nft_child_content">
            <img :src="item.url" alt="" class="nft_child_img" />
            <div class="nft_child_title">{{ item.translatedTitle }}</div>
          </div>

          <div class="nft_child_num-c">
            <div class="nft_child_num">
              <p style="margin-right: 5px">X</p>
              <p class="nft_child_num_p2">{{ item.num }}</p>
            </div>

            <p class="nft_child_num_p3">{{ t("打点奖励基数") }}</p>
          </div>
        </div>
      </div>
      <div class="nft-content" style="margin-top: 30px">
        <div class="nft_child" v-for="(item, i) in translatedNftData2" :key="i">
          <div class="nft_child_content">
            <img :src="item.url" alt="" class="nft_child_img" />
            <div class="nft_child_title">{{ item.translatedTitle }}</div>
          </div>

          <div class="nft_child_num-c">
            <div class="nft_child_num">
              <p style="margin-right: 5px">X</p>
              <p class="nft_child_num_p2">{{ item.num }}</p>
            </div>

            <p class="nft_child_num_p3">{{ t("打点奖励基数") }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="not-nft_page">
      <h1>{{ t("非车主服饰NFT") }}</h1>

      <div class="nft-content">
        <div class="nft_child" v-for="(item, i) in translatedNftData" :key="i">
          <div class="nft_child_content">
            <img :src="item.url" alt="" class="nft_child_img" />
            <div class="nft_child_title">{{ item.translatedTitle }}</div>
          </div>

          <div class="nft_child_num-c">
            <div class="nft_child_num">
              <p style="margin-right: 2px">x</p>
              <p class="nft_child_num_p2">{{ item.num }}</p>
            </div>

            <p class="nft_child_num_p3">{{ t("打点奖励基数") }}</p>
          </div>
        </div>
      </div>
      <div class="nft-content">
        <div class="nft_child" v-for="(item, i) in translatedNftData2" :key="i">
          <div class="nft_child_content">
            <img :src="item.url" alt="" class="nft_child_img" />
            <div class="nft_child_title">{{ item.translatedTitle }}</div>
          </div>

          <div class="nft_child_num-c">
            <div class="nft_child_num">
              <p style="margin-right: 2px">x</p>
              <p class="nft_child_num_p2">{{ item.num }}</p>
            </div>

            <p class="nft_child_num_p3">{{ t("打点奖励基数") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { computed, ref } from "vue";

const { t } = useI18n();

const nftData = ref([
  {
    url: require("@/assets/01.png"),
    titleKey: "晨曦之装",
    num: "2.0",
  },
  {
    url: require("@/assets/02.png"),
    titleKey: "晨曦之装",
    num: "2.0",
  },
  {
    url: require("@/assets/03.png"),
    titleKey: "晨曦之装",
    num: "2.0",
  },
  {
    url: require("@/assets/04.png"),
    titleKey: "霞光之服",
    num: "3.0",
  },
  {
    url: require("@/assets/05.png"),
    titleKey: "霞光之服",
    num: "3.0",
  },
  {
    url: require("@/assets/06.png"),
    titleKey: "霞光之服",
    num: "3.0",
  },
  // {
  //   url: require("@/assets/衣服3.png"),
  //   titleKey: "月华之衣",
  //   num: "6.0",
  // },
  // {
  //   url: require("@/assets/衣服4.png"),
  //   titleKey: "日曜之袍",
  //   num: "10.0",
  // },
]);

const nftData2 = ref([
  {
    url: require("@/assets/07.png"),
    titleKey: "月华之衣",
    num: "6.0",
  },
  {
    url: require("@/assets/08.png"),
    titleKey: "月华之衣",
    num: "6.0",
  },
  {
    url: require("@/assets/09.png"),
    titleKey: "月华之衣",
    num: "6.0",
  },
  {
    url: require("@/assets/10.png"),
    titleKey: "日曜之袍",
    num: "10.0",
  },
  {
    url: require("@/assets/11.png"),
    titleKey: "日曜之袍",
    num: "10.0",
  },
  {
    url: require("@/assets/12.png"),
    titleKey: "日曜之袍",
    num: "10.0",
  },
]);

const translatedNftData = computed(() =>
  nftData.value.map((item) => ({
    ...item,
    translatedTitle: t(item.titleKey), // 使用计算属性获取翻译的标题
  }))
);
const translatedNftData2 = computed(() =>
  nftData2.value.map((item) => ({
    ...item,
    translatedTitle: t(item.titleKey), // 使用计算属性获取翻译的标题
  }))
);
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  .not-nft {
    display: none;
  }

  .not-nft_page {
    display: block;
    width: 95%;
    margin: 80px auto;
    color: #fff;

    h1 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .nft-content {
      width: 100%;
      height: 258px;
      margin-bottom: 20px;
      overflow-x: scroll;
      display: flex;
      justify-content: space-evenly;

      .nft_child {
        width: 135px;
        height: 198px;
        background: #22222866;
        border-radius: 8px;

        .nft_child_content {
          width: 126px;
          height: 195px;
          background: rgba(217, 217, 217, 0.1);
          border-radius: 8px;
          margin: 4px;
          display: flex;
          justify-content: center;
          position: relative;

          .nft_child_img {
            width: 100px;
            height: 150px;
            margin-top: 10px;
          }

          .nft_child_title {
            font-size: 10px;
            position: absolute;
            bottom: 10px;
            left: 10px;
          }
        }

        .nft_child_num-c {
          margin-top: 7px;
          margin-left: 15px;
          font-weight: 600;

          .nft_child_num {
            display: flex;
            align-items: flex-end;

            p {
              font-size: 9px;
            }

            .nft_child_num_p2 {
              font-size: 9px;
              font-weight: 600;
              line-height: 12px;
            }
          }

          .nft_child_num_p3 {
            font-size: 9px;
            font-weight: 400;
            color: #ffffff66;
            margin-top: 3px;
          }
        }
      }
    }
  }
}

@media (min-width: 601px) {
  .not-nft_page {
    display: none;
  }

  .not-nft {
    width: 100%;
    background: #0e0e10;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 0;

    h1 {
      font-size: 40px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 60px;
    }

    .nft-content {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      overflow-x: scroll;

      .nft_child {
        width: 270px;
        height: 478px;
        background: #22222866;
        border-radius: 8px;
        margin-right: 20px;

        .nft_child_content {
          width: 262px;
          height: 390px;
          background: rgba(217, 217, 217, 0.1);
          border-radius: 8px;
          margin: 4px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: relative;

          .nft_child_img {
            width: 200px;
            height: 330px;
            margin: 10px auto;
            margin-bottom: 0;
          }

          .nft_child_title {
            font-size: 20px;
            position: absolute;
            bottom: 10px;
            left: 20px;
          }
        }

        .nft_child_num-c {
          margin-top: 13px;
          margin-left: 15px;
          font-weight: 600;

          .nft_child_num {
            display: flex;
            align-items: flex-end;

            p {
              font-size: 18px;
            }

            .nft_child_num_p2 {
              font-size: 30px;
              line-height: 33px;
            }
          }

          .nft_child_num_p3 {
            font-size: 18px;
            font-weight: 400;
            color: #ffffff66;
            margin-top: 5px;
          }
        }
      }
    }
  }
}
</style>
