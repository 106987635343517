export default {
  lang: "zh-TW",
  关于: "關於",
  角色: "角色",
  NFT: "NFT",
  CPLE: "CPLE",
  发布行程: "發布行程",
  购买: "購買",
  "PolloGo World 是一个结合了区块链技术与绿色出行理念的创新项目。它通过顺风车、步行和公共交通等多种方式，让用户不仅享受便捷的出行，还可以赚取CPLE代币奖励，实现经济收益。凭借其多样化的奖励机制、NFT 系统和长期的代币分配模型，PolloGo 为用户提供了一个独特的「边走边赚」的机会。展望未来，PolloGo World 有望成为区块链领域中出行类应用的先锋，吸引越来越多的用户加入并从中受益。在全球环境问题日益严重的背景下，PolloGo 的绿色出行理念也符合可持续发展的趋势，既为用户带来便利，又为环境保护贡献力量。":
    "PolloGo World 是一個結合了區塊鏈技術與綠色出行理念的創新項目。它通過順風車、步行和公共交通等多種方式，讓用戶不僅享受便捷的出行，還可以賺取CPLE代幣獎勵，實現經濟收益。憑藉其多樣化的獎勵機制、NFT 系統和長期的代幣分配模型，PolloGo 為用戶提供了一個獨特的「邊走邊賺」的機會。展望未來，PolloGo World 有望成為區塊鏈領域中出行類應用的先鋒，吸引越來越多的用戶加入並從中受益。在全球環境問題日益嚴重的背景下，PolloGo 的綠色出行理念也符合可持續發展的趨勢，既為用戶帶來便利，又為環境保護貢獻力量。",
  "日常行程赚取 CPLE": "日常行程賺取 CPLE",
  "在 PolloGo 项目中，用户可以通过完成顺风车、步行或使用公共交通来赚取 CPLE 代币。行程的奖励基于“打卡点”机制，奖励取决于打卡点的数量和每个打卡点的奖励值。":
    "在 PolloGo 項目中，用戶可以通過完成順風車、步行或使用公共交通來賺取 CPLE 代幣。行程的獎勵基於「打卡點」機制，獎勵取決於打卡點的數量和每個打卡點的獎勵值。",
  单个行程的奖励规则: "單個行程的獎勵規則",
  "奖励计算公式：行程奖励 = 打卡点数量 × 单个打卡点奖励值":
    "獎勵計算公式：行程獎勵 = 打卡點數量 × 單個打卡點獎勵值",
  "单个打卡点奖励值：公式：个人奖励基数 × (个人质押数量÷10,000)¹/⁴ 注意：每次行程的最高奖励按 70,000 的 N% 计算。":
    "單個打卡點獎勵值：公式：個人獎勵基數 × (個人質押數量÷10,000)¹/⁴ \n注意：每次行程的最高獎勵按 70,000 的 N% 計算。",
  顺风车司机: "順風車司機",
  "每日可获得额外 2 个行程次数": "每日可獲得額外 2 個行程次數",
  出行达人: "出行達人",
  "每日可获得 5 个额外行程次数": "每日可獲得 5 個額外行程次數",
  车队老板: "車隊老闆",
  "每日可获得 100 个额外行程次数，可以将行程次数赠送给好友":
    "每日可獲得 100 個額外行程次數，可以將行程次數贈送給好友",
  城市合伙人: "城市合夥人",
  "每日可获得 1000 个额外行程次数，可以将行程次数赠送给好友":
    "每日可獲得 1000 個額外行程次數，可以將行程次數贈送給好友",
  车主汽车NFT: "車主汽車NFT",
  新星之驾: "新星之駕",
  银翼之旅: "銀翼之旅",
  金辉之路: "金輝之路",
  耀世之行: "耀世之行",
  传奇之旅: "傳奇之旅",
  非车主服饰NFT: "非車主服飾NFT",
  普通款: "普通款",
  晨曦之装: "晨曦之裝",
  霞光之服: "霞光之服",
  月华之衣: "月華之衣",
  日曜之袍: "日曜之袍",
  免费: "免費",
  打点奖励基数: "打點獎勵基數",
  什么是CPLE: "什麼是CPLE",
  "CPLE（Carpool Life Economy）是一种基于区块链的顺风车平台的Token，用于奖励用户并促进平台内的经济活动。目前由ITB PLE CO., LIMITED运营，代表项目有“趣出行”和“菠萝快跑”，并正尝试通过BTALK.AI与更多优质项目互相赋能来促进平台内的经济活动。":
    "CPLE（Carpool Life Economy）是一種基於區塊鏈的順風車平台的Token，用於獎勵用戶並促進平台內的經濟活動。目前由ITB PLE CO., LIMITED運營，代表項目有「趣出行」和「菠蘿快跑」，並正嘗試通過BTALK.AI與更多優質項目互相賦能來促進平台內的經濟活動。",
  "在STON.FI上购买": "在STON.FI上購買",
};
