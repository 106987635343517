export default {
  lang: "en-PH",
  关于: "Tungkol",
  角色: "Gampanin",
  NFT: "NFT",
  CPLE: "CPLE",
  发布行程: "I-publish ang biyahe",
  购买: "Bumili",
  "PolloGo World 是一个结合了区块链技术与绿色出行理念的创新项目。它通过顺风车、步行和公共交通等多种方式，让用户不仅享受便捷的出行，还可以赚取CPLE代币奖励，实现经济收益。凭借其多样化的奖励机制、NFT 系统和长期的代币分配模型，PolloGo 为用户提供了一个独特的「边走边赚」的机会。展望未来，PolloGo World 有望成为区块链领域中出行类应用的先锋，吸引越来越多的用户加入并从中受益。在全球环境问题日益严重的背景下，PolloGo 的绿色出行理念也符合可持续发展的趋势，既为用户带来便利，又为环境保护贡献力量。":
    "Ang PolloGo World ay isang makabagong proyekto na pinagsasama ang teknolohiya ng blockchain at ang konsepto ng berdeng transportasyon. Sa pamamagitan ng iba't ibang paraan tulad ng pagsasama ng sasakyan, paglalakad, at pampasaherong transportasyon, maaaring hindi lamang tamasahin ng mga gumagamit ang maginhawang paglalakbay kundi kumita rin ng mga gantimpala sa CPLE tokens, na nagreresulta sa mga pang-ekonomiyang benepisyo. Sa pamamagitan ng iba't ibang sistema ng gantimpala, sistema ng NFT, at pangmatagalang modelo ng pamamahagi ng tokens, nag-aalok ang PolloGo sa mga gumagamit ng isang natatanging pagkakataon na 'kumita habang naglalakad'. Sa hinaharap, inaasahang magiging nangunguna ang PolloGo World sa mga aplikasyon ng transportasyon sa larangan ng blockchain, na umaakit ng mas maraming gumagamit at pinapayagan silang makinabang. Sa harap ng lumalalang mga isyu sa kapaligiran sa buong mundo, ang berdeng transportasyon ng PolloGo ay naaayon din sa trend ng napapanatiling pag-unlad, na nagdadala ng kaginhawaan sa mga gumagamit at nag-aambag sa pangangalaga ng kapaligiran.",
  "日常行程赚取 CPLE": "Kumita ng CPLE sa mga pang-araw-araw na biyahe",
  "在 PolloGo 项目中，用户可以通过完成顺风车、步行或使用公共交通来赚取 CPLE 代币。行程的奖励基于“打卡点”机制，奖励取决于打卡点的数量和每个打卡点的奖励值。":
    "Sa proyekto ng PolloGo, maaaring kumita ang mga gumagamit ng CPLE tokens sa pamamagitan ng pagkumpleto ng pagsasama ng sasakyan, paglalakad, o paggamit ng pampasaherong transportasyon. Ang mga gantimpala sa biyahe ay batay sa mekanismo ng 'mga punto ng kontrol', at nakasalalay sa bilang ng mga punto ng kontrol at ang halaga ng gantimpala ng bawat punto ng kontrol.",
  单个行程的奖励规则:
    "Mga panuntunan sa gantimpala para sa isang solong biyahe",
  "奖励计算公式：行程奖励 = 打卡点数量 × 单个打卡点奖励值":
    "Pormula ng pagkalkula ng gantimpala: Gantimpala ng biyahe = Bilang ng mga punto ng kontrol × Halaga ng gantimpala ng isang punto ng kontrol",
  "单个打卡点奖励值：公式：个人奖励基数 × (个人质押数量÷10,000)¹/⁴ 注意：每次行程的最高奖励按 70,000 的 N% 计算。":
    "Halaga ng gantimpala ng isang punto ng kontrol: Pormula: Personal na base ng gantimpala × (Bilang ng personal na nakalagak na tokens ÷ 10,000)¹/⁴ \nTandaan: Ang pinakamataas na gantimpala para sa bawat biyahe ay kinakalkula ayon sa N% ng 70,000.",
  顺风车司机: "Driver ng pagsasama ng sasakyan",
  "每日可获得额外 2 个行程次数":
    "Maaaring makakuha ng 2 karagdagang biyahe bawat araw.",
  出行达人: "Dalubhasa sa paglalakbay",
  "每日可获得 5 个额外行程次数":
    "Maaaring makakuha ng 5 karagdagang biyahe bawat araw.",
  车队老板: "May-ari ng fleet",
  "每日可获得 100 个额外行程次数，可以将行程次数赠送给好友":
    "Maaaring makakuha ng 100 karagdagang biyahe bawat araw at maaring ibigay ang mga biyahe sa mga kaibigan.",
  城市合伙人: "Katrabaho sa lungsod",
  "每日可获得 1000 个额外行程次数，可以将行程次数赠送给好友":
    "Maaaring makakuha ng 1000 karagdagang biyahe bawat araw at maaring ibigay ang mga biyahe sa mga kaibigan.",
  车主汽车NFT: "NFT ng sasakyan para sa may-ari",
  新星之驾: "Bituin na Driver",
  银翼之旅: "Paglalakbay ng mga pilak na pakpak",
  金辉之路: "Daan ng gintong liwanag",
  耀世之行: "Kislap ng paglalakbay",
  传奇之旅: "Mahiwagang paglalakbay",
  非车主服饰NFT: "NFT ng kasuotan para sa mga hindi may-ari",
  普通款: "Karaniwang Edisyon",
  晨曦之装: "Damit ng bukang-liwayway",
  霞光之服: "Damit ng sikat ng araw",
  月华之衣: "Damit ng buwan",
  日曜之袍: "Robe ng araw",
  免费: "Libre",
  打点奖励基数: "Base ng gantimpala",
  什么是CPLE: "Ano ang CPLE",
  "CPLE（Carpool Life Economy）是一种基于区块链的顺风车平台的Token，用于奖励用户并促进平台内的经济活动。目前由ITB PLE CO., LIMITED运营，代表项目有“趣出行”和“菠萝快跑”，并正尝试通过BTALK.AI与更多优质项目互相赋能来促进平台内的经济活动。":
    "Ang CPLE (Carpool Life Economy) ay isang token para sa isang platform ng ridesharing na batay sa blockchain, na ginagamit upang gantimpalaan ang mga gumagamit at itaguyod ang mga aktibidad na pang-ekonomiya sa loob ng platform. Sa kasalukuyan, ito ay pinapatakbo ng ITB PLE CO., LIMITED, na kumakatawan sa mga proyektong tulad ng 'Masayang Paglalakbay' at 'Pineapple Express', at sinisikap na bigyang kapangyarihan ang mas maraming kalidad na proyekto sa pamamagitan ng BTALK.AI upang itaguyod ang mga aktibidad na pang-ekonomiya sa loob ng platform.",
  "在STON.FI上购买": "Bumili sa STON.FI",
};
