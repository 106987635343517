export default {
  lang: "ja",
  关于: "について",
  角色: "役割",
  NFT: "NFT",
  CPLE: "CPLE",
  发布行程: "旅行を公開する",
  购买: "購入",
  "PolloGo World 是一个结合了区块链技术与绿色出行理念的创新项目。它通过顺风车、步行和公共交通等多种方式，让用户不仅享受便捷的出行，还可以赚取CPLE代币奖励，实现经济收益。凭借其多样化的奖励机制、NFT 系统和长期的代币分配模型，PolloGo 为用户提供了一个独特的「边走边赚」的机会。展望未来，PolloGo World 有望成为区块链领域中出行类应用的先锋，吸引越来越多的用户加入并从中受益。在全球环境问题日益严重的背景下，PolloGo 的绿色出行理念也符合可持续发展的趋势，既为用户带来便利，又为环境保护贡献力量。":
    "PolloGo Worldは、ブロックチェーン技術とグリーン移動の理念を組み合わせた革新的なプロジェクトです。ライドシェア、ウォーキング、公共交通などさまざまな方法で、ユーザーは便利な移動を楽しむだけでなく、CPLEトークンの報酬を得て経済的利益を実現できます。多様な報酬メカニズム、NFTシステム、長期的なトークン分配モデルを通じて、PolloGoはユーザーにユニークな「歩きながら稼ぐ」機会を提供します。未来を見据えると、PolloGo Worldはブロックチェーン分野における移動アプリケーションの先駆者となり、ますます多くのユーザーを引き付け、利益を享受することが期待されます。世界的な環境問題が深刻化する中、PolloGoのグリーン移動の理念は持続可能な発展の傾向にも合致しており、ユーザーに便利さを提供しながら環境保護にも貢献しています。",
  "日常行程赚取 CPLE": "日常の行程でCPLEを稼ぐ",
  "在 PolloGo 项目中，用户可以通过完成顺风车、步行或使用公共交通来赚取 CPLE 代币。行程的奖励基于“打卡点”机制，奖励取决于打卡点的数量和每个打卡点的奖励值。":
    "PolloGoプロジェクトでは、ユーザーはライドシェア、ウォーキング、または公共交通機関を利用してCPLEトークンを稼ぐことができます。行程の報酬は「チェックインポイント」メカニズムに基づいており、報酬はチェックインポイントの数と各チェックインポイントの報酬値に依存します。",
  单个行程的奖励规则: "単一行程の報酬ルール",
  "奖励计算公式：行程奖励 = 打卡点数量 × 单个打卡点奖励值":
    "報酬計算公式：行程報酬 = チェックインポイントの数 × 単一チェックインポイントの報酬値",
  "单个打卡点奖励值：公式：个人奖励基数 × (个人质押数量÷10,000)¹/⁴ 注意：每次行程的最高奖励按 70,000 的 N% 计算。":
    "単一チェックインポイントの報酬値：公式：個人報酬基準 × (個人ステーキング数÷10,000)¹/⁴ \n注意：各行程の最高報酬は70,000のN%で計算されます。",
  顺风车司机: "ライドシェアドライバー",
  "每日可获得额外 2 个行程次数": "毎日追加で2つの行程を取得できます。",
  出行达人: "旅行の達人",
  "每日可获得 5 个额外行程次数": "毎日追加で5つの行程を取得できます。",
  车队老板: "車両チームのオーナー",
  "每日可获得 100 个额外行程次数，可以将行程次数赠送给好友":
    "毎日追加で100の行程を取得でき、友達に行程を贈ることができます。",
  城市合伙人: "都市パートナー",
  "每日可获得 1000 个额外行程次数，可以将行程次数赠送给好友":
    "毎日追加で1000の行程を取得でき、友達に行程を贈ることができます。",
  车主汽车NFT: "車両オーナーの自動車NFT",
  新星之驾: "スタードライバー",
  银翼之旅: "銀の翼の旅",
  金辉之路: "金の輝く道",
  耀世之行: "輝く旅",
  传奇之旅: "伝説の旅",
  非车主服饰NFT: "非車両オーナーの衣服NFT",
  普通款: "スタンダードエディション",
  晨曦之装: "朝焼けの装い",
  霞光之服: "霞の服",
  月华之衣: "月光の衣",
  日曜之袍: "太陽のローブ",
  免费: "無料",
  打点奖励基数: "報酬基準",
  什么是CPLE: "CPLEとは何か",
  "CPLE（Carpool Life Economy）是一种基于区块链的顺风车平台的Token，用于奖励用户并促进平台内的经济活动。目前由ITB PLE CO., LIMITED运营，代表项目有“趣出行”和“菠萝快跑”，并正尝试通过BTALK.AI与更多优质项目互相赋能来促进平台内的经济活动。":
    "CPLE（Carpool Life Economy）は、ブロックチェーンベースのライドシェアプラットフォーム用のトークンであり、ユーザーに報酬を与え、プラットフォーム内の経済活動を促進するために使用されます。現在、ITB PLE CO., LIMITEDによって運営されており、「楽しい旅行」と「パイナップルエクスプレス」というプロジェクトを代表しており、BTALK.AIを通じてより多くの質の高いプロジェクトを相互支援し、プラットフォーム内の経済活動を促進しようとしています。",
  "在STON.FI上购买": "STON.FIで購入する",
};
