export default {
  lang: "zh",
  关于: "关于",
  角色: "角色",
  NFT: "NFT",
  CPLE: "CPLE",
  发布行程: "发布行程",
  购买: "购买",
  "PolloGo World 是一个结合了区块链技术与绿色出行理念的创新项目。它通过顺风车、步行和公共交通等多种方式，让用户不仅享受便捷的出行，还可以赚取CPLE代币奖励，实现经济收益。凭借其多样化的奖励机制、NFT 系统和长期的代币分配模型，PolloGo 为用户提供了一个独特的「边走边赚」的机会。展望未来，PolloGo World 有望成为区块链领域中出行类应用的先锋，吸引越来越多的用户加入并从中受益。在全球环境问题日益严重的背景下，PolloGo 的绿色出行理念也符合可持续发展的趋势，既为用户带来便利，又为环境保护贡献力量。":
    "PolloGo World 是一个结合了区块链技术与绿色出行理念的创新项目。它通过顺风车、步行和公共交通等多种方式，让用户不仅享受便捷的出行，还可以赚取CPLE代币奖励，实现经济收益。凭借其多样化的奖励机制、NFT 系统和长期的代币分配模型，PolloGo 为用户提供了一个独特的「边走边赚」的机会。展望未来，PolloGo World 有望成为区块链领域中出行类应用的先锋，吸引越来越多的用户加入并从中受益。在全球环境问题日益严重的背景下，PolloGo 的绿色出行理念也符合可持续发展的趋势，既为用户带来便利，又为环境保护贡献力量。",
  "日常行程赚取 CPLE": "日常行程赚取 CPLE",
  "在 PolloGo 项目中，用户可以通过完成顺风车、步行或使用公共交通来赚取 CPLE 代币。行程的奖励基于“打卡点”机制，奖励取决于打卡点的数量和每个打卡点的奖励值。":
    "在 PolloGo 项目中，用户可以通过完成顺风车、步行或使用公共交通来赚取 CPLE 代币。行程的奖励基于“打卡点”机制，奖励取决于打卡点的数量和每个打卡点的奖励值。",
  单个行程的奖励规则: "单个行程的奖励规则",
  "奖励计算公式：行程奖励 = 打卡点数量 × 单个打卡点奖励值":
    "奖励计算公式：行程奖励 = 打卡点数量 × 单个打卡点奖励值",
  "单个打卡点奖励值：公式：个人奖励基数 × (个人质押数量÷10,000)¹/⁴ 注意：每次行程的最高奖励按 70,000 的 N% 计算。":
    "单个打卡点奖励值：公式：个人奖励基数 × (个人质押数量÷10,000)¹/⁴ \n注意：每次行程的最高奖励按 70,000 的 N% 计算。",
  顺风车司机: "顺风车司机",
  "每日可获得额外 2 个行程次数": "每日可获得额外 2 个行程次数",
  出行达人: "出行达人",
  "每日可获得 5 个额外行程次数": "每日可获得 5 个额外行程次数",
  车队老板: "车队老板",
  "每日可获得 100 个额外行程次数，可以将行程次数赠送给好友":
    "每日可获得 100 个额外行程次数，可以将行程次数赠送给好友",
  城市合伙人: "城市合伙人",
  "每日可获得 1000 个额外行程次数，可以将行程次数赠送给好友":
    "每日可获得 1000 个额外行程次数，可以将行程次数赠送给好友",
  车主汽车NFT: "车主汽车NFT",
  新星之驾: "新星之驾",
  银翼之旅: "银翼之旅",
  金辉之路: "金辉之路",
  耀世之行: "耀世之行",
  传奇之旅: "传奇之旅",
  非车主服饰NFT: "非车主服饰NFT",
  普通款: "普通款",
  晨曦之装: "晨曦之装",
  霞光之服: "霞光之服",
  月华之衣: "月华之衣",
  日曜之袍: "日曜之袍",
  免费: "免费",
  打点奖励基数: "打点奖励基数",
  什么是CPLE: "什么是CPLE",
  "CPLE（Carpool Life Economy）是一种基于区块链的顺风车平台的Token，用于奖励用户并促进平台内的经济活动。目前由ITB PLE CO., LIMITED运营，代表项目有“趣出行”和“菠萝快跑”，并正尝试通过BTALK.AI与更多优质项目互相赋能来促进平台内的经济活动。":
    "CPLE（Carpool Life Economy）是一种基于区块链的顺风车平台的Token，用于奖励用户并促进平台内的经济活动。目前由ITB PLE CO., LIMITED运营，代表项目有“趣出行”和“菠萝快跑”，并正尝试通过BTALK.AI与更多优质项目互相赋能来促进平台内的经济活动。",
  "在STON.FI上购买": "在STON.FI上购买",
};
